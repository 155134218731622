import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import BlogPostPreviews from '../../components/common/blog-post-previews';
import UpcomingCourses from '../../components/home/upcoming-courses';
import SEO from '../../components/seo';

const ChildFamilySpecialists = ({ data }) => {
  const childFamilySpecialistsCourses = data.courses.edges.filter((course) =>
    course?.node?.subcategory?.includes('child & family specialists')
  );
  const seoTitle =
    data.pageSeo?.edges[0]?.node?.pageName || 'Child & family specialists';
  const seoDescription =
    data.pageSeo?.edges[0]?.node?.description?.internal?.content ||
    'Child & family specialists';
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="100%"
        mt={6}
        mb={16}
      >
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          w="100%"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            flexDir="column"
            w="100%"
            mr={16}
            maxW="600px"
            order={{ base: 2, lg: 1 }}
          >
            <Flex
              w="100%"
              flexDir="column"
              justifyContent="flex-start"
              flexGrow={1}
            >
              <Heading as="h1" mt={10}>
                {data.header.nodes[0].heading}
              </Heading>
              <Text color="gray.600" mt={6}>
                <ReactMarkdown>
                  {data.header.nodes[0].introParagraph.internal.content}
                </ReactMarkdown>
              </Text>
            </Flex>
          </Flex>
          <Box
            w="100%"
            maxW={{ base: '100%', lg: '50%' }}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="lg"
            order={{ base: 1, lg: 2 }}
          >
            <Img
              image={getImage(data.header.nodes[0]?.headerImage?.gatsbyImage)}
              alt={data.header.nodes[0].headerImage.title}
            />
          </Box>
        </Flex>
        <UpcomingCourses
          courses={childFamilySpecialistsCourses}
          category={true}
        />
        <BlogPostPreviews />
      </Flex>
    </>
  );
};

export default ChildFamilySpecialists;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(
      filter: { pageName: { eq: "child-family-specialists" } }
    ) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    header: allContentfulPageHeader(
      filter: { pageIdentifier: { eq: "child & family specialists" } }
    ) {
      nodes {
        heading
        id
        contentful_id
        introParagraph {
          internal {
            content
          }
        }
        headerImage {
          title
          gatsbyImage(width: 700)
        }
      }
    }
    courses: allContentfulCourse(
      filter: { subcategory: { eq: "child & family specialists" } }
    ) {
      edges {
        node {
          courseCategory
          courseImage {
            title
            gatsbyImage(width: 400)
          }
          subcategory
          slug
          courseName
          forParents
          shortDescription {
            raw
          }
        }
      }
    }
  }
`;
